<template>
  <div class="row" v-if="cible">
    <div class="col col-md-8">
      <div class="column">
        <EvenementsTimeline :evenements="evenements" color="per" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { EvenementsTimeline } from '../../components/evenements'

export default {
  name: 'CibleEvenements',
  components: { EvenementsTimeline },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (!store.getters['evenements/getFilters']) {
      store.dispatch('evenements/fetchFilters')
    }
  },
  data () {
    return {
      isFormOpen: false
    }
  },

  computed: {
    ...mapGetters({
      cible: 'cibles/getCurrentCible',
      filters: 'evenements/getFilters',
      getEvenementsFor: 'evenements/getEvenementsFor'
    }),

    evenements () {
      return this.getEvenementsFor('cible', this.cible.id)
    }
  },

  watch: {
    filters: {
      handler (newVal, oldVal) {
        let filters = {}
        newVal.forEach(filter => {
          if (filter.value !== undefined) filters[filter.id] = filter.value
        })
        this.fetchEvenements(filters)
      },
      deep: true
    }
  },

  mounted () {
    this.fetchEvenements()
  },

  methods: {
    fetchEvenements (params) {
      this.$q.loading.show()
      return this.$store.dispatch('evenements/fetchEvenements', {
        model_type: 'groupeCible',
        model_id: this.$route.params.id,
        rowsPerPage: 0,
        ...params
      }).then(() => { this.$q.loading.hide() })
    }
  }
}
</script>

<style>
</style>
